import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoCompleteItem from './autoCompleteItem/autoCompleteItem';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { FormattedMessage } from 'react-intl';
import mediaQueries from 'app/utils/mediaQueries';

const { maxSuggestions } = window.inlineSearchConfiguration;

class SearchBox extends Component {
  constructor() {
    super();
    this.resetQuery = this.resetQuery.bind(this);
  }

  componentDidMount() {
    const maxLength = 256;
    document.querySelector('.search-box-component').addEventListener('searchSectionClosed', this.resetQuery);
    document.querySelector('.bootstrap-typeahead-input-main').setAttribute('maxlength', maxLength);
  }

  componentWillUnmount() {
    document.querySelector('.search-box-component').removeEventListener('searchSectionClosed', this.resetQuery);
  }

  resetQuery() {
    this._typeahead.getInstance().clear();
  }

  render() {
    const {
      options, searchQuery, onSearchChange, onSubmit, onSearchSubmitClick, onAutoCompleteClick
    } = this.props;
    const placeHolderMsg = mediaQueries.is_small_only() ?
      this.context.intl.formatMessage({ id: 'ui.search.placeholder.medium', description: 'Buscar en Betterware.com.mx' }) :
      this.context.intl.formatMessage({ id: 'ui.search.placeholder.medium', description: 'Buscar en Betterware.com.mx' });

      // if(searchQuery.length > 2){
      //   document.querySelector('.glide__bullets').setAttribute('class', 'glide__bullets inactive');
      // } else {
      //   document.querySelector('.glide__bullets').setAttribute('class', 'glide__bullets');
      // }

    return (
      <form onSubmit={(e) => {
        onSearchSubmitClick();
        onSubmit(e, searchQuery.trim());
      }}
      >
        <AsyncTypeahead
          options={options}
          className="autosuggest search-input"
          submitFormOnEnter
          filterBy={() => true}
          useCache={false}
          minLength={2}
          maxResults={maxSuggestions}
          onSearch={onSearchChange}
          onInputChange={onSearchChange}
          placeholder={placeHolderMsg}
          searchText=""
          promptText=""
          paginationText=""
          emptyLabel=""
          ref={ref => (this._typeahead = ref)}
          renderMenuItemChildren={(result, menuProps) => autoCompleteItem(result, menuProps, onSubmit, onAutoCompleteClick)}
          title={placeHolderMsg}
        />
        <button className={`button search__submit${searchQuery.trim() !== '' ? ' active' : ''}`} type="submit">
          <span className="icon-search"/>
        </button>
      </form>
    );
  }
}

SearchBox.propTypes = {
  options: PropTypes.array,
  searchQuery: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSearchSubmitClick: PropTypes.func.isRequired,
  onAutoCompleteClick: PropTypes.func.isRequired
};

SearchBox.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default SearchBox;
