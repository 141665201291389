import { flatten } from 'lodash';
import { RECEIVE_AUTOCOMPLETE_OPTIONS, UPDATE_SEARCH_QUERY } from './searchBoxConstants';
import { ajaxRequest } from 'app/utils/helpers';

const { searchAutocompleteRunners, keywordMinLength } = window.inlineSearchConfiguration;

const runSearchAutocomplete = (url, query) => (
  ajaxRequest('GET', url, { query }).then(
    json => json
  )
);

export const updateSearchQuery = (query = '') => ({
  type: UPDATE_SEARCH_QUERY, query
});

const receiveAutocompleteOptions = (options = []) => ({
  type: RECEIVE_AUTOCOMPLETE_OPTIONS,
  options
});

const fetchSearchOptions = (query) => (dispatch) => {
  const promises = searchAutocompleteRunners.map(
    (autocompleteConfig) => runSearchAutocomplete(autocompleteConfig.searchUrl, query)
  );

  // return a promise that combines all promises from data sources
  return Promise.all(promises)
    .then(
      // @TODO: need to calarify/do stable sort to preserve solr ordering
      (results) => {
        dispatch(receiveAutocompleteOptions(
          flatten(results.map((result) => result.suggestions))
            .filter((el) => el) // remove undefined
        ));
      },
      () => dispatch(receiveAutocompleteOptions())
    );
};

export const updateSearchResults = (query = '') => (dispatch, getState) => {
  if (query.toLowerCase() === getState().searchQuery.toLowerCase()) {
    return;
  }

  dispatch(updateSearchQuery(query));
  if (query.length < keywordMinLength) {
    dispatch(receiveAutocompleteOptions());
  } else {
    dispatch(fetchSearchOptions(query));
  }
};
