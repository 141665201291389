import { RECEIVE_AUTOCOMPLETE_OPTIONS, UPDATE_SEARCH_QUERY } from './searchBoxConstants';

export const options = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_AUTOCOMPLETE_OPTIONS:
      return [...action.options];
    default:
      return state;
  }
};

export const searchQuery = (state = '', action) => {
  switch (action.type) {
    case UPDATE_SEARCH_QUERY:
      return action.query;
    default:
      return state;
  }
};
