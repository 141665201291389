import { connect } from 'react-redux';
import SearchBox from './searchBox';
import { updateSearchResults } from './searchBoxActions';
import { PREDICTIVE_SEARCH, TEXT_SEARCH } from './searchBoxConstants';

const { searchPageUrl } = window.inlineSearchConfiguration;

const mapStateToProps = (state) => ({
  options: state.options,
  searchQuery: state.searchQuery
});

const mapDispatchToProps = (dispatch) => ({
  onSearchChange: (query) => {
    dispatch(updateSearchResults(query));
  },
  onSubmit: (event, searchKeyword) => {
    if (searchKeyword !== '') {
      window.location.href = `${window.location.origin}${searchPageUrl}?query=${searchKeyword}`;
    }

    event.preventDefault();
  },
  onSearchSubmitClick: () => {
    dispatch({ type: TEXT_SEARCH });
  },
  onAutoCompleteClick: () => {
    dispatch({ type: PREDICTIVE_SEARCH });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
