import React from 'react';
import PropTypes from 'prop-types';
import Highlight from 'react-highlighter';

const autoCompleteItem = (option, props, onSubmit, onAutoCompleteClick) => (
  <div className="autosuggest__item">
    <Highlight className="autosuggest__item--highlight" search={props.text} onClick={(e) => {
      onAutoCompleteClick();
      onSubmit(e, option);
    }}
    >{option}</Highlight>
  </div>
);

autoCompleteItem.propTypes = {
  text: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onAutoCompleteClick: PropTypes.func.isRequired
};

export default autoCompleteItem;
